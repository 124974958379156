<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="iq-card">
        <div class="iq-card-body p-0">
          <div class="iq-edit-list">
            <ul class="iq-edit-profile d-flex nav nav-pills">
              <li class="col-md-6 p-0">
                <a class="nav-link active" data-toggle="pill" href="#personal-information">
                  Personal Information
                </a>
              </li>
              <li class="col-md-6 p-0">
                <a class="nav-link" data-toggle="pill" href="#chang-pwd">
                  Change Password
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-12">
      <div class="iq-edit-list-data">
        <div class="tab-content">
          <div class="tab-pane fade active show" id="personal-information" role="tabpanel">
            <div class="iq-card" v-if="loading">
              <LoadingSpinner :loading="loading" />
            </div>
            <div class="iq-card" v-else>
              <div class="iq-card-header d-flex justify-content-between">
                <div class="iq-header-title">
                  <h4 class="card-title">Personal Information</h4>
                </div>
              </div>
              <div class="iq-card-body">
                <form @submit.prevent="onSubmit">
                  <div class="row align-items-center">
                    <div class="form-group col-sm-6">
                      <label for="uname">User Name:</label>
                      <input type="text" class="form-control" id="uname" v-model="user.username"
                        style="border-color:lightgray; height:38px;" readonly>
                    </div>
                    <div class="form-group col-sm-6">
                      <label for="cname">Email:</label>
                      <div class="input-with-icon">
    
                      <input type="text" class="form-control" id="cname" v-model="user.email"
                        style="border-color:lightgray; height:38px;" readonly>
                        <span class="icon is-small is-left">
      <i class="fas fa-envelope"></i>
    </span>
                        </div>
                    </div>
                    <div class="form-group col-sm-6">
                      <label for="fname">First Name:</label>
                      <input type="text" class="form-control" @keydown="preventLeadingSpace($event, 'fname')"
                        @input="validateName('first_name')" id="fname" v-model="user.first_name"
                        style="border-color:lightgray; height:38px;" maxlength="15">
                      <!-- Error message for First Name -->
                      <div v-if="!user.first_name.trim()" class="error-message">This field is required.</div>
                      <div v-if="firstNameError" class="error-message">{{ firstNameError }}</div>
                    </div>
                    <div class="form-group col-sm-6">
                      <label for="lname">Last Name:</label>
                      <input type="text" class="form-control" @keydown="preventLeadingSpace($event, 'lname')"
                        @input="validateName('last_name')" id="lname" v-model="user.last_name"
                        style="border-color:lightgray; height:38px;" maxlength="15">
                      <!-- Error message for Last Name -->
                      <div v-if="!user.last_name.trim()" class="error-message">This field is required.</div>
                      <div v-if="lastNameError" class="error-message">{{ lastNameError }}</div>
                    </div>
                  </div>
                  <button type="submit" class="btn btn-primary mr-2" :disabled="isSubmitting">Update</button>
                  <button type="reset" class="btn iq-bg-danger">Cancel</button>
                </form>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="chang-pwd" role="tabpanel">
            <div class="iq-card">
              <div class="iq-card-header d-flex justify-content-between">
                <div class="iq-header-title">
                  <h4 class="card-title">Change Password</h4>
                </div>
              </div>
              <div class="iq-card-body">
                <form @submit.prevent="changePassword">
                  <div class="form-group">
                    <label for="cpass">Old Password:</label>
                    <div class="input-with-icon">
                    <input type="text" v-if="showOldPassword" class="form-control" @input="validatePassword"
                      @keydown="preventLeadingSpace($event, 'currentPassword')" id="cpass" v-model="currentPassword"
                      style="border-color:lightgray; height:38px;" maxlength="20">
                    <input type="password" v-else class="form-control" @input="validatePassword"
                      @keydown="preventLeadingSpace($event, 'currentPassword')" id="cpass" v-model="currentPassword"
                      style="border-color:lightgray; height:38px;" maxlength="20">
                      <span class="icon is-small is-right" @click="oldpasswordtoggleShow">
              <i class="fas" :class="{ 'fa-eye-slash': showOldPassword, 'fa-eye': !showOldPassword }"></i>
            </span>
          </div>
                    <div v-if="passwordError" class="error-message">{{ passwordError }}</div>
                  </div>
                  <div class="form-group">
                    <label for="pass">New Password:</label>
                    <div class="input-with-icon">
                    <input type="text" v-if="showNewPassword" class="form-control" @input="validatePassword"
                      @keydown="preventLeadingSpace($event, 'newPassword')" id="pass" v-model="newPassword"
                      style="border-color:lightgray; height:38px;" maxlength="20">
                    <input type="password" v-else class="form-control" @input="validatePassword"
                      @keydown="preventLeadingSpace($event, 'newPassword')" id="pass" v-model="newPassword"
                      style="border-color:lightgray; height:38px;" maxlength="20">
                      <span class="icon is-small is-right" @click="newpasswordtoggleShow">
              <i class="fas" :class="{ 'fa-eye-slash': showNewPassword, 'fa-eye': !showNewPassword }"></i>
            </span>
          </div>
                    <div v-if="newPasswordError" class="error-message">{{ newPasswordError }}</div>
                  </div>
                  <div class="form-group">
                    <label for="cpass">Confirm New Password:</label>
                    <div class="input-with-icon">
                    <input type="passwotextrd" v-if="showConfirmPassword" class="form-control" @input="validatePassword"
                      @keydown="preventLeadingSpace($event, 'confirm_new_password')" id="confirm_new_password"
                      v-model="confirm_new_password" style="border-color:lightgray; height:38px;" maxlength="20">
                    <input type="password" v-else class="form-control" @input="validatePassword"
                      @keydown="preventLeadingSpace($event, 'confirm_new_password')" id="confirm_new_password"
                      v-model="confirm_new_password" style="border-color:lightgray; height:38px;" maxlength="20">
                      <span class="icon is-small is-right" @click="confirmpasswordtoggleShow">
              <i class="fas" :class="{ 'fa-eye-slash': showConfirmPassword, 'fa-eye': !showConfirmPassword }"></i>
            </span>
          </div>
                    <div v-if="confirmNewPasswordError" class="error-message">{{ confirmNewPasswordError }}</div>
                  </div>
                  <button type="submit" class="btn btn-primary mr-2" :disabled="isSubmitting">Update</button>
                  <button type="reset" class="btn iq-bg-danger">Cancel</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { core } from '../../config/pluginInit'
import axios from "axios";
import constant, { toastMessage,decryptToken } from "@/config/constant";
import LoadingSpinner from "@/config/Pageloader.vue";
import CryptoJS from "crypto-js";
export default {
  name: 'ProfileEdit',
  components: {
    LoadingSpinner,
  },
  mounted() {
    core.index();
    this.fetchUserDetails();

  },
  data() {
    return {
      showConfirmPassword: false,
      showNewPassword: false,
      showOldPassword: false,
      loading: true,
      isSubmitting: false, // Define isSubmitting in the data object
      user: {
        first_name: '',
        last_name: '',
      },
      currentPassword: '',
      newPassword: '',
      confirm_new_password: '',
      message: '',
      newPasswordError: '',
      confirmNewPasswordError: '',
      passwordError: '',
      firstNameError: '',
      lastNameError: '',
    }
  },

  methods: {
    //old password show hide
    oldpasswordtoggleShow() {
      this.showOldPassword = !this.showOldPassword;
    },
    //new password show hide
    newpasswordtoggleShow() {
      this.showNewPassword = !this.showNewPassword;
    },
    //Confirm password show hide
    confirmpasswordtoggleShow() {
      this.showConfirmPassword = !this.showConfirmPassword;
    },
    //Remove space first letter
    preventLeadingSpace(event, field) {
      if (event.key === ' ' && (event.target.selectionStart === 0 || this.user[field] === '')) {
        event.preventDefault();
      } else {
        this.user[field] = event.target.value.trim();
      }
    },
    //Password Validetions
    validatePassword() {
      const regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{5,}$/;

      // Validate current password
      if (!regex.test(this.currentPassword)) {
        this.passwordError = "Password must be at least 5 characters long and contain at least one letter, one number, and one special character.";
      } else {
        this.passwordError = '';
      }

      // Validate new password
      if (!regex.test(this.newPassword)) {
        this.newPasswordError = "Password must be at least 5 characters long and contain at least one letter, one number, and one special character.";
      } else {
        this.newPasswordError = '';
      }

      // Validate confirm new password
      if (this.confirm_new_password !== this.newPassword) {
        this.confirmNewPasswordError = "Passwords do not match.";
      } else {
        this.confirmNewPasswordError = '';
      }
    },
    //Fetch user profile data
    async fetchUserDetails() {
      try {
        const token = localStorage.getItem("token");
        const key = "enc"; // Fetch encrypted token
        const decryptedToken = decryptToken(token, key);
        const response = await axios.get(
          constant.API_BASE_URL + "me/",
          {
            headers: { Authorization: "Token " + decryptedToken },
          }
        );
        this.user = response.data.data[0];
        this.loading = false;
      } catch (error) {
        console.error("Error fetching user details:", error);
        this.loading = false;
      }
    },
    //Validetion profile field
    validateProfileFields() {
      let isValid = true;
      this.firstNameError = '';
      this.lastNameError = '';

      if (!this.user.first_name.trim()) {
        this.firstNameError = 'This field is required.';
        isValid = false;
      }

      if (!this.user.last_name.trim()) {
        this.lastNameError = 'This field is required.';
        isValid = false;
      }
      this.validateName('first_name');
      this.validateName('last_name');

      if (this.firstNameError || this.lastNameError) {
        isValid = false;
      }

      return isValid;
    },

    //Name validetions
    validateName(field) {
      const regex = /^[A-Za-z]+$/;
      if (!regex.test(this.user[field])) {
        if (field === 'first_name') {
          this.firstNameError = 'First name must contain only letters.';
        } else if (field === 'last_name') {
          this.lastNameError = 'Last name must contain only letters.';
        }
      } else {
        if (field === 'first_name') {
          this.firstNameError = '';
        } else if (field === 'last_name') {
          this.lastNameError = '';
        }
      }
    },

    //Profile update
    async onSubmit() {
      if (!this.validateProfileFields()) {
        return;
      }
      try {
        const userData = {
          first_name: this.user.first_name,
          last_name: this.user.last_name,
        };

        const token = localStorage.getItem("token");
        const key = "enc";
        const decryptedToken =decryptToken(token, key);
        this.isSubmitting = true; // Enable the submit button
        const response = await axios.patch(
          constant.API_BASE_URL + "me/profile/", // Append user ID to the API endpoint
          userData,
          {
            headers: {
              Authorization: "Token " + decryptedToken,
            },
          }
        );
        this.message = response.data.message
        // Set the icon to "success" for successful response
        this.icon = "success"
        // Call the toastMessage function to display a toast notification
        toastMessage(this.icon, this.message);

        // Optionally, you can redirect to another page after successful update
        // this.$router.push('/profile'); // Change the route as per your application
      } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
          this.message = error.response.data.message;
        } else {
          this.message = "Something went wrong. Please try again later.";
        }
        this.icon = "error"
        // Call the toastMessage function to display a toast notification
        toastMessage(this.icon, this.message);

      }
      this.isSubmitting = false; // Enable submit button
    },

    //Password update
    async changePassword() {
      this.validatePassword();

      // Check if any validation errors exist
      if (this.passwordError || this.newPasswordError || this.confirmNewPasswordError) {
        return; // Don't proceed further if there are validation errors
      }


      try {
        // Check if new password matches the confirmed password
        this.isSubmitting = true; // Enable the submit button

        const passwordData = {
          password: this.currentPassword,
          new_password: this.newPassword,
          confirm_new_password: this.confirm_new_password,  // Include confirm new password field
        };

        const token = localStorage.getItem("token");
        const key = "enc";
        const decryptedToken =decryptToken(token, key);

        const response = await axios.patch(
          constant.API_BASE_URL + "change-password/",
          passwordData,
          {
            headers: {
              Authorization: "Token " + decryptedToken,
            },
          }
        );
        this.message = response.data.message // Set the error message from response
        // Set the icon to "success" for successful response
        this.icon = "success"
        // Call the toastMessage function to display a toast notification
        toastMessage(this.icon, this.message);
        // Clear password fields
        this.currentPassword = '';
        this.newPassword = '';
        this.confirm_new_password = '';
      } catch (error) {
        this.message = error.response.data.message; // Set the error message from response
        // Set the icon to "success" for successful response
        this.icon = "error"
        // Call the toastMessage function to display a toast notification
        toastMessage(this.icon, this.message);



      }
      this.isSubmitting = false; // Enable submit button

    }

  }
}
</script>

<style scoped>
.error-message {
  color: red;
  font-size: 12px;
}
</style>